import axios from "axios"
import { getInstance } from "../auth/index";

export default {
  async getListRoles(){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/role", { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  }
}