<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-if="!loading">
      <h5 class="text-center text-primary">User management</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Select user</strong>
            <b-list-group>
              <b-list-group-item v-for="item in users" :key="item.email" href=# v-on:click="populate(item.email)">
                <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>
                {{generateNameLabel(item.given_name, item.family_name, item.nickname, item.email)}}
              </b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary">Step 2: Update/delete user</strong>
            <b-form @submit.prevent="onSubmit" @reset.prevent>
              <b-form-group id="input-group-4" label="E-mail" label-for="input-4">
                <b-form-input
                  id="input-4"
                  type="email"
                  disabled
                  v-model="form.email"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label="Given name*"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  type="text"
                  v-model="form.given_name"
                  :disabled="!form.user_id.startsWith('auth0|')"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Family name*" label-for="input-2">
                <b-form-input
                  id="input-2"
                  type="text"
                  v-model="form.family_name"
                  :disabled="!form.user_id.startsWith('auth0|')"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="Nickname*" label-for="input-3">
                <b-form-input
                  id="input-3"
                  type="text"
                  v-model="form.nickname"
                  :disabled="!form.user_id.startsWith('auth0|')"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-5" label="Mobile" label-for="input-5">
                <b-form-input
                  id="input-5"
                  type="tel"
                  v-model="form.user_metadata.mobile"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-6" label="Street name and number" label-for="input-6">
                <b-form-input
                  id="input-6"
                  type="text"
                  v-model="form.user_metadata.address.street"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-7" label="Postcode" label-for="input-7">
                <b-form-input
                  id="input-7"
                  type="text"
                  v-model="form.user_metadata.address.postcode"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-8" label="City/Town" label-for="input-8">
                <b-form-input
                  id="input-8"
                  type="text"
                  v-model="form.user_metadata.address.city"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-9" label="Country" label-for="input-9">
                <b-form-input
                  id="input-9"
                  type="text"
                  v-model="form.user_metadata.address.country"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-10" label="Child #1 account" label-for="input-10">
                <b-form-select v-model="form.user_metadata.parentsof1" :options="useroptions" 
                  :disabled="!form.user_metadata.roles.includes(roleParents)" value=null></b-form-select>
              </b-form-group>
              <b-form-group id="input-group-11" label="Child #2 account" label-for="input-11">
                <b-form-select v-model="form.user_metadata.parentsof2" :options="useroptions" :disabled="!form.user_metadata.roles.includes(roleParents)"></b-form-select>
              </b-form-group>
              <b-form-group id="input-group-12" label="Child #3 account" label-for="input-12">
                <b-form-select v-model="form.user_metadata.parentsof3" :options="useroptions" :disabled="!form.user_metadata.roles.includes(roleParents)"></b-form-select>
              </b-form-group>
              <b-form-group id="input-group-13" label="Short notes for map teaching session" label-for="input-13">
                <b-form-input
                  id="input-13"
                  type="text"
                  v-model="form.user_metadata.notes"
                ></b-form-input>
              </b-form-group>

              <b-alert 
                :show="form.user_metadata.roles.includes(roleChild) && form.user_metadata.roles.length > 1" 
                dismissible 
                variant="danger" 
                >
                Student account cannot be combined with other roles
              </b-alert>

              <b-form-group label="Roles assignment">
                <b-form-checkbox-group
                  :options="roleoptions"
                  v-model="form.user_metadata.roles"
                  name="roles-assignment"
                  @change="roleChanged()"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
              <b-button type="submit" variant="primary" :disabled="form.user_id == ''">Update</b-button>
              <b-button type="reset" variant="danger" @click="modalShow = !modalShow" v-b-modal.modal-center :disabled="form.user_id == ''">Delete</b-button>
            </b-form>
            <div class="mt-4" />
            <b-alert 
              :show="dismissCountDown" 
              dismissible 
              variant="success" 
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
              >
              {{ alertMessage }}
            </b-alert>
            <b-alert
              :show="dismissErrCountDown"
              dismissible
              variant="danger"
              @dismissed="dismissErrCountDown=0"
              @dismiss-count-down="countDownErrChanged"
            >
              {{ alertErrMessage }}
            </b-alert>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mt-4" />
            * Non editable if users sign up using external identity provider (e.g., Google). Change them directly on the external identity provider sites. 
          </b-col>
        </b-row>
      </b-container>

      <b-modal id="modal-prevent-closing" centered title="Delete confirmation" v-model="modalShow" @ok="handleOk">
        Are you sure want to delete this account?
      </b-modal>
    </span>

    <div class="mt-3" />
  </div>
</template>

<script>
  import UserService from '@/services/UserService.js';
  import RoleService from '@/services/RoleService.js';

  export default {
    name: 'usermanagement',
    title: "TPA Gembira - User Management",
    data() {
      return {
        alertMessage: "",
        dismissSecs: 5,
        dismissCountDown: 0,
        dismissErrSecs: 5,
        dismissErrCountDown: 0,
        alertErrMessage: "",
        modalShow: false,
        loading: false,
        users: [],
        useroptions: [],
        roleoptions: [],
        roleParents: null,
        roleChild: null,
        form: {
          email: "",
          family_name: "",
          given_name: "",
          nickname: "",
          user_id: "",
          user_metadata: {
            address: {
              street: "",
              postcode: "",
              city: "",
              country: ""
            },
            mobile: "",
            parentsof1: null,
            parentsof2: null,
            parentsof3: null,
            notes: "",
            roles: []
          },
        },
      }
    },
    created(){
      this.init();
    },
    methods: {
      roleChanged(){
        if (!this.form.user_metadata.roles.includes(this.roleParents)){
          // role child must be nullify
          this.form.user_metadata.parentsof1 = null;
          this.form.user_metadata.parentsof2 = null;
          this.form.user_metadata.parentsof3 = null;
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      countDownErrChanged(dismissErrCountDown) {
        this.dismissErrCountDown = dismissErrCountDown
      },
      showSuccessConfirm(msg) {
        this.dismissCountDown = this.dismissSecs;
        this.alertMessage = msg;
      },
      showErrorConfirm(msg){
        this.dismissErrCountDown = this.dismissErrSecs
        this.alertErrMessage = msg;
      },
      async getListUsers(){
        UserService.getListUsers()
          .then(
            (list => {
              this.$set(this, "users", list);
              // users for visualization only
              for (let item of  list){
                this.useroptions.push(
                  { 'value': item.user_id, 'text': this.generateNameLabel(item.given_name, item.family_name, item.nickname, item.email)}, 
                );
              }
              this.useroptions.push({ 'value': null, 'text': 'None'});
            }).bind(this)
          );
      },
      async getListRoles(){
        if (this.roleoptions.length == 0){
          RoleService.getListRoles()
            .then(
              (listRoles => {
                for (let role of listRoles){
                  this.roleoptions.push(
                    {'value': role.id, 'text': role.name }
                  );
                  if (role.name === "Orangtua"){
                    this.roleParents = role.id;
                  }
                  if (role.name === "Murid"){
                    this.roleChild = role.id;
                  }
                }
              }).bind(this)
            );
        }
      },
      async init(){
        this.loading = true;
        await Promise.all ([this.getListUsers(), this.getListRoles()])
          .then(() => {
            this.loading = false;
          });
      },
      async populate(email){
        let userDetail = await UserService.getUserDetails(email);
        this.form.user_id = userDetail.user_id;
        this.form.given_name = typeof(userDetail.given_name)==="undefined" ? "" : userDetail.given_name;
        this.form.family_name = typeof(userDetail.family_name)==="undefined" ? "" : userDetail.family_name;
        this.form.nickname = typeof(userDetail.nickname)==="undefined" ? "" : userDetail.nickname;
        this.form.email = typeof(userDetail.email)==="undefined" ? "" : userDetail.email;
        this.form.user_metadata.roles = [];

        if (typeof(userDetail.user_metadata)==="undefined"){
          this.form.user_metadata.mobile = "";
          this.form.user_metadata.address.street = "";
          this.form.user_metadata.address.postcode = "";
          this.form.user_metadata.address.city = "";
          this.form.user_metadata.address.country = "";
          this.form.user_metadata.parentsof1 = null;
          this.form.user_metadata.parentsof2 = null;
          this.form.user_metadata.parentsof3 = null;
          this.form.user_metadata.notes = "";
        } else {
          this.form.user_metadata.mobile = typeof(userDetail.user_metadata.mobile)==="undefined" ? "" : userDetail.user_metadata.mobile;
          this.form.user_metadata.address.street = typeof(userDetail.user_metadata.address.street)==="undefined" ? "" : userDetail.user_metadata.address.street;
          this.form.user_metadata.address.postcode = typeof(userDetail.user_metadata.address.postcode)==="undefined" ? "" : userDetail.user_metadata.address.postcode;
          this.form.user_metadata.address.city = typeof(userDetail.user_metadata.address.city)==="undefined" ? "" : userDetail.user_metadata.address.city;
          this.form.user_metadata.address.country = typeof(userDetail.user_metadata.address.country)==="undefined" ? "" : userDetail.user_metadata.address.country;
          this.form.user_metadata.parentsof1 = null;
          this.form.user_metadata.parentsof2 = null;
          this.form.user_metadata.parentsof3 = null;

          // create array of user_id as reference
          let indexuserid = [];
          this.users.forEach((item) => {
            indexuserid.push(item.user_id);
          });

          if (typeof(userDetail.user_metadata.parentsof)!=="undefined" && userDetail.user_metadata.parentsof.length > 0){
            userDetail.user_metadata.parentsof = userDetail.user_metadata.parentsof.filter(item => indexuserid.includes(item.user_id));
            this.form.user_metadata.parentsof1 = typeof(userDetail.user_metadata.parentsof[0])==="undefined"? null : userDetail.user_metadata.parentsof[0].user_id;
            this.form.user_metadata.parentsof2 = typeof(userDetail.user_metadata.parentsof[1])==="undefined"? null : userDetail.user_metadata.parentsof[1].user_id;
            this.form.user_metadata.parentsof3 = typeof(userDetail.user_metadata.parentsof[2])==="undefined"? null : userDetail.user_metadata.parentsof[2].user_id;
          }
          if (typeof(userDetail.roles)!=="undefined"){
            userDetail.roles.forEach((item) => {
              this.form.user_metadata.roles.push(item.id);
            })
          }
        }
        let userNotes = await UserService.getUserNotes([this.form.user_id]);
        this.form.user_metadata.notes = userNotes !== null && userNotes.length == 1 ? userNotes[0].notes : '';

      },
      onSubmit(){
        // check rules
        if (this.form.user_metadata.roles.includes(this.roleParents) && this.form.user_metadata.roles.includes(this.roleChild)) {
          this.showErrorConfirm("Cannot add conflicting roles in a single account, e.g. 'Orangtua' with 'Murid'");
        } else {
            
          // create array of user_id as reference
          let indexuserid = [];
          this.users.forEach((item) => {
            indexuserid.push(item.user_id);
          });

          let parentsofarrsubmission = [];
          let parentsofarrfrm = [this.form.user_metadata.parentsof1, this.form.user_metadata.parentsof2, this.form.user_metadata.parentsof3];
          parentsofarrfrm.forEach((user_id) => {
            if (user_id !== null){
              // enrich user_id with email, using items in users
              let idx = indexuserid.findIndex((user_id_idx) => {
                return user_id_idx === user_id; 
              });
              if (idx > -1){
                parentsofarrsubmission.push( { 
                  "user_id" : this.users[idx].user_id, 
                  "email" : this.users[idx].email, 
                  "given_name" : this.users[idx].given_name, 
                  "family_name" : this.users[idx].family_name,
                  "nickname": this.users[idx].nickname,
                  "picture" : this.users[idx].picture });
              }
            }
          });

          let userdata = {
            "user_id": this.form.user_id,
            "given_name": this.form.given_name,
            "family_name": this.form.family_name,
            "nickname": this.form.nickname,
            "user_metadata": {
              "address": {
                "street": this.form.user_metadata.address.street,
                "postcode": this.form.user_metadata.address.postcode,
                "city": this.form.user_metadata.address.city,
                "country": this.form.user_metadata.address.country
              },
              "mobile": this.form.user_metadata.mobile,
              "parentsof": parentsofarrsubmission
            },
            "roles": this.form.user_metadata.roles
          }
          UserService.upsertUserDetails(userdata);

          UserService.upsertUserNotes({ "user_id" : this.form.user_id, "notes": this.form.user_metadata.notes});
          this.showSuccessConfirm("Update user submitted successfully");

          for (let i=0; i < this.useroptions.length; i++){
            if (this.useroptions[i].value == this.form.user_id){
              this.useroptions[i].text = this.generateNameLabel(this.form.given_name, this.form.family_name, this.form.nickname, this.form.email)
              this.users[i].given_name = this.form.given_name;
              this.users[i].family_name = this.form.family_name;
              this.users[i].nickname = this.form.nickname;
              break;
            }
          }
        }
        
      },
      async handleOk(bvModalEvt) {
        if (this.form.user_id == "" || typeof(this.form.user_id) === "undefined"){
          this.showErrorConfirm("Please choose a user")
        } else {
          // Prevent modal from closing
          bvModalEvt.preventDefault();
          if (typeof(this.form.user_id)!=='undefined' && typeof(this.$auth.user.sub)!=='undefined' && (this.$auth.user.sub) == (this.form.user_id)) {
            this.showErrorConfirm("Not allowed to delete own account. Please use other pengurus/admin account.");
          } else {
            let index = 0;
            while (index < this.useroptions.length && this.useroptions[index].value !== this.form.user_id){
              index++;
            }
            if (index < this.useroptions.length){
              this.useroptions.splice(index, 1);
              this.users.splice(index, 1);
            }
            UserService.deleteUser(this.form.user_id);
            this.showSuccessConfirm("User delete submitted successfully. Account marked for deletion.")
          }

          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })
        }
      },
    }
  } 
</script>

<style lang="sass" scoped>
</style>