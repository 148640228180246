import axios from "axios"
import { getInstance } from "../auth/index";

export default {
  async getListUsers(){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/user", { headers: {authorization: `Bearer ${accessToken}`}});
    // let res = await axios.get("http://localhost:3000/user", { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
  async getUserDetails(email){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/user/" + email, { headers: {authorization: `Bearer ${accessToken}`}});
    // let res = await axios.get("http://localhost:3000/user/" + email, { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
  async upsertUserDetails(userdata){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'POST',
      url: "https://tpagembira.azurewebsites.net/user",
      // url: "http://localhost:3000/user",
      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      },
      data: userdata
    }
    axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
    return "OK";
  },
  async deleteUser(userid){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'DELETE',
      url: "https://tpagembira.azurewebsites.net/user/" + userid,
      // url: "http://localhost:3000/user/" + userid,
      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      }
    }
    return axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
  },
  async getUserNotes(useridarrays){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let query = "";
    useridarrays.forEach(userid => {
      query += "user_id=";
      query += userid
      query += "&";
    });
    let res = await axios.get("https://tpagembira.azurewebsites.net/usernotes?" + query, { headers: {authorization: `Bearer ${accessToken}`}});
    // let res = await axios.get("http://localhost:3000/usernotes?" + query, { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
  async upsertUserNotes(notesdata){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'POST',
      url: "https://tpagembira.azurewebsites.net/usernotes",
      // url: "http://localhost:3000/usernotes",
      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      },
      data: notesdata
    }

    axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
    return "OK";
  }
}